import { template as template_03390ac3c4854ee785da1dc08e963235 } from "@ember/template-compiler";
const FKText = template_03390ac3c4854ee785da1dc08e963235(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
