import { template as template_def36d58edbc4f00bee0742960c0d179 } from "@ember/template-compiler";
const FKControlMenuContainer = template_def36d58edbc4f00bee0742960c0d179(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
