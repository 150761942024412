import { template as template_722bf907e8f64e6f9f519e695e051b3b } from "@ember/template-compiler";
const WelcomeHeader = template_722bf907e8f64e6f9f519e695e051b3b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
